import { atom } from 'jotai';
import { atomWithStorage, createJSONStorage } from 'jotai/utils';

import type { InvoiceRegisterDto } from '@claim/ClaimDetail/apis/type';

const storage = createJSONStorage(() => localStorage);

export const userIdAtom = atomWithStorage('sellerUserId', '', storage);

export const visibleAtom = atomWithStorage(
  'isVisible',
  'invisible',
  // import.meta.env.VITE_ENV === 'production' ? 'invisible' : 'visible',
  storage
);

export const claimExchangeDirtyAtom = atom<InvoiceRegisterDto[]>([]);
